.loading-photo{
    position: relative;
    border-radius : 5px;
    width :15px;
    height : 15px;
    top : 10%;
    left : 80%;
    translate : transform(0,-50%);
    background : #fff;

}
.loading-hexagon
{
    position: relative;
    border-radius : 10px;
    width: 100px;
    height: 50px;
    /* background-color: #111; */
    background: rgb(34,21,235);
    background: linear-gradient(90deg, rgba(34,21,235,1) 0%, rgba(86,86,201,1) 0%, rgba(54,164,236,1) 86%);
    margin: 2px;
    /* clip-path: polygon(50% 0%, 100% 25%, 100% 75%, 50% 100%, 0% 75%, 0% 25%); */
    transition: 2s;
}
.loading-hexagon:before{
    content:'';
    position: absolute;
    border-radius : 5px;
    width: 50%;
    height: 100%;
    pointer-events: none;
    background: rgba(255,255,255,0.02);
    z-index: 1000;
}
.loading-hexagon::after {
	content:'';
    top:0;
    border-radius : 5px;
	/* transform:translateX(100%); */
	width:10%;
	height:100%;
	position: absolute;
	z-index:1;
	animation: loading-slide 1s infinite;
	 
  /* 
  CSS Gradient - complete browser support from http://www.colorzilla.com/gradient-editor/ 
  */
  background: -moz-linear-gradient(left, rgba(255,255,255,0) 0%, rgba(255,255,255,0.8) 50%, rgba(128,186,232,0) 99%, rgba(125,185,232,0) 100%); /* FF3.6+ */
	background: -webkit-gradient(linear, left top, right top, color-stop(0%,rgba(255,255,255,0)), color-stop(50%,rgba(255,255,255,0.8)), color-stop(99%,rgba(128,186,232,0)), color-stop(100%,rgba(125,185,232,0))); /* Chrome,Safari4+ */
	background: -webkit-linear-gradient(left, rgba(255,255,255,0) 0%,rgba(255,255,255,0.8) 50%,rgba(128,186,232,0) 99%,rgba(125,185,232,0) 100%); /* Chrome10+,Safari5.1+ */
	background: -o-linear-gradient(left, rgba(255,255,255,0) 0%,rgba(255,255,255,0.8) 50%,rgba(128,186,232,0) 99%,rgba(125,185,232,0) 100%); /* Opera 11.10+ */
	background: -ms-linear-gradient(left, rgba(255,255,255,0) 0%,rgba(255,255,255,0.8) 50%,rgba(128,186,232,0) 99%,rgba(125,185,232,0) 100%); /* IE10+ */
	background: linear-gradient(to right, rgba(255,255,255,0) 0%,rgba(255,255,255,0.8) 50%,rgba(128,186,232,0) 99%,rgba(125,185,232,0) 100%); /* W3C */
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#007db9e8',GradientType=1 ); /* IE6-9 */
}

.loading-container{
    position: absolute;
    border-radius : 10px;
    height: 100vh;
    left:50%;
    top:40%;
    /* margin-top: 30%; */
    transform: translate(-50%)
    /* overflow: hidden; */
    /* animation: loading-animate 4s linear infinite; */
}

@keyframes loading-slide {
	0% {transform:translateX(0%);}
	100% {transform:translateX(900%);}
}

@keyframes loading-animate 
{
    0%
    {
        filter: hue-rotate(0deg);
    }
    100%
    {
        filter: hue-rotate(360deg);
    }
}
/* 
.row {
    display: inline-flex;
}

.row:nth-child(even)
{
    margin-left: 1px;
} */