.dropdown, .textbox-div, .radiobutton-div, .checkbox-div{
    color: rgb(125, 125, 235);
    /* margin: 0px 0px 0px 15px; */
    text-align: left;
}

.textbox-div label {
margin-left: 5px;
}
.textbox-div {
  display: flex;
  flex-direction: column;
}
.radiobutton-btns-div{
  display: flex;
  flex-direction: row;
}
.radio-btn, .radio-btn-label{
  margin: 0px 10px 0px 0px;
}
.dropdown-content{
    width: 100%;
    padding: 3px;
    border: 1px solid rgb(228, 221, 221);
    border-radius: 3px;
    outline: none;
}
.textbox{
    width: 100%;
    border: 1px solid rgb(228, 221, 221);
    border-radius: 5px;
    padding: 4px;
    outline: none;
    background-color: white;
}
.textbox:focus, .dropdown-content:focus{
    border: 1px solid skyblue;
}
.checkbox{
    margin: 0px 5px 0px 0px;
}