.payment-title{
   padding: 50px 0px 20px 0px;
}
#first-left-col,
#first-right-col{
    text-align: left;
    margin: 10px 0px 0px 50px;
}
.left-col-element, .right-col-element{
    color: rgb(27, 139, 245);
    margin-top: 15px;
}
#payment-image{
    border-radius: 200px;
}
#order-box{
    padding: 20px 0px 30px 0px;
    width: 300px;
    border-radius: 50px;
    text-align: center;
}
#order-box label{
    padding: 20px 0px 0px 0px;
}
#order-box:hover{
    background-color: white;
}